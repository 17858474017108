import React, { MouseEventHandler } from "react";
import SuccessModal from "../SuccessModal";

import styles from "./SaveWarningModal.module.css";

export type SaveWarningModalProps = {
  onConfirmation: (arg: void) => void;
};

const SaveWarningModal: React.FC<SaveWarningModalProps> = ({
  onConfirmation,
}) => {
  return (
    <SuccessModal
      type={"neutral"}
      title={"Aviso"}
      onClose={onConfirmation}
      content='Recuerda guardar tus cambios con el botón "Guardar cambios".'
    />
  );
};

export default SaveWarningModal;
