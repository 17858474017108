import React, { useState, ChangeEvent, MouseEventHandler } from "react";

import Checkbox from "../Checkbox";
import ToggleArrow from "../ToggleArrow";

import classnames from "classnames";
import styles from "./FoldedTable.module.css";
import Lottie from "lottie-react";
import { ReactComponent as Empty } from "./empty.svg";
import { ReactComponent as TrashcanIcon } from "./trashcanIcon.svg";
import { ReactComponent as EditIcon } from "./editIcon.svg";
import animation from "./check.json";

import { Business, Operation } from "../../types";
import ControlledInput from "../ControlledInput";

export type tableProps = {
  business: Business[] | undefined;
  onValueChange: (oarg: string) => (arg: ChangeEvent<HTMLInputElement>) => void;
  onValueOperChange: (
    opId: string,
    busId: string
  ) => (arg: ChangeEvent<HTMLInputElement>) => void;
  radioButtonClick: (arg: Business) => MouseEventHandler;
  onEditBusiness: (arg: Business) => (ev: any) => void;
  onEraseBusiness: (arg: Business) => (ev: any) => void;
  onActivateSendClick: (arg: Business, oper?: Operation) => (ev: any) => void;
};

const Table: React.FC<tableProps> = ({
  business,
  onValueOperChange,
  onValueChange,
  radioButtonClick,
  onEditBusiness,
  onEraseBusiness,
  onActivateSendClick,
}) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const isBusinessExpanded = (bus: Business) => {
    return expanded.indexOf(bus.id) !== -1;
  };

  const expandClick = (bus: Business) => (ev: any) => {
    const index = expanded.indexOf(bus.id);
    if (index === -1) {
      setExpanded((state) => [...state, bus.id]);
    } else {
      setExpanded((state) => state.filter((item) => item !== bus.id));
    }
  };

  return (
    <table className={styles.foldedTable}>
      <thead>
        <tr>
          {/* <td style={{ margin: "0 auto" }}>
            <Checkbox value={true} label={""} onClick={() => {}} />
          </td> */}
          <td
            className={styles.businessColumn}
            style={{
              textAlign: "left",
              paddingLeft: 0,
              paddingRight: "80px",
            }}
          >
            Negocio
          </td>
          <td>Origen</td>
          <td>Usuarios</td>
          <td>Asignaciones</td>
          <td style={{ whiteSpace: "nowrap" }}>TOP 3</td>
          <td style={{ whiteSpace: "nowrap" }}>Plantilla</td>
          <td style={{ whiteSpace: "nowrap" }}>Editar</td>
          <td style={{ whiteSpace: "nowrap" }}>Eliminar</td>
          <td style={{ whiteSpace: "nowrap" }}>Enviar</td>
          <td className={styles.phantomCell}></td>
        </tr>
      </thead>
      <tbody>
        {business?.map((bus) => (
          <>
            <tr className={styles.businessRow} key={bus.id}>
              <td className={styles.businessColumn}>
                <div className={styles.businessName} onClick={expandClick(bus)}>
                  {bus.name}
                  {!!bus.operations && (
                    <ToggleArrow value={isBusinessExpanded(bus)} />
                  )}
                </div>
              </td>
              <td className={styles.origin}>
                {bus.process?.toUpperCase() || ""}
              </td>
              <td
                className={styles.userCount}
              >{`${bus.count} / ${bus.total}`}</td>
              <td>
                <ControlledInput
                  placeholder="..."
                  className={styles.inputValue}
                  value={bus.value}
                  onChange={onValueChange(bus.id)}
                />
              </td>
              <td>
                <Checkbox
                  value={bus.top}
                  label={""}
                  onClick={radioButtonClick(bus)}
                  iconType={"circle"}
                />
              </td>
              <td>
                <CheckMark check={!!bus.template} />
              </td>
              <td>
                <EditIcon onClick={onEditBusiness(bus)} />
              </td>
              <td>
                <TrashcanIcon onClick={onEraseBusiness(bus)} />
              </td>
              <td>
                <Checkbox
                  iconType={"message"}
                  value={bus.send}
                  label={""}
                  onClick={onActivateSendClick(bus)}
                />
              </td>
              <td className={styles.phantomCell}></td>
            </tr>
            {!!bus.operations &&
              bus.operations?.map((oper) => (
                <tr
                  className={classnames(styles.operationRow, {
                    [styles.rowShowing]: isBusinessExpanded(bus),
                    [styles.rowHidden]: !isBusinessExpanded(bus),
                  })}
                  key={oper.id}
                >
                  <td style={{ textAlign: "left" }}>{oper.name}</td>
                  <td>{bus.process?.toUpperCase() || ""}</td>
                  <td>{`${oper.count} / ${oper.total}`}</td>
                  <td>
                    <ControlledInput
                      placeholder="..."
                      className={styles.inputOperValue}
                      value={oper.value}
                      onChange={onValueOperChange(oper.id, bus.id)}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Checkbox
                      value={oper.send}
                      label={""}
                      onClick={onActivateSendClick(bus, oper)}
                    />
                  </td>
                </tr>
              ))}
          </>
        ))}
      </tbody>
    </table>
  );
};

export default Table;

const CheckMark: React.FC<{ check: boolean }> = ({ check }) => {
  return check ? (
    <Lottie animationData={animation} style={{ height: "52px" }} loop={false} />
  ) : (
    <Empty />
  );
};
