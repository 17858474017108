import React, { useEffect, ChangeEvent, useState, useRef } from "react";

import FormModal from "../FormModal";
import StyledInput from "../StyledInput";
import Switch from "../Switch";
import Button from "../Button";

import { ReactComponent as CancelIcon } from "./cancelIcon.svg";
import { ReactComponent as TrashIcon } from "./trashIcon.svg";
import { ReactComponent as BookIcon } from "./bookIcon.svg";
import styles from "./EditBusinessModal.module.css";

import { Business, Operation } from "../../types";

export type EditBusinessModalProps = {
  editBusinessInfo?: Business;
  onCancel: (arg: void) => void;
  onFinish: (arg: Business) => void;
};

const EditBusinessModal: React.FC<EditBusinessModalProps> = ({
  editBusinessInfo,
  onCancel,
  onFinish,
}) => {
  const [id, setId] = useState(
    editBusinessInfo?.id || "temp" + Math.floor(1000 * Math.random())
  );
  const [busName, setBusName] = useState(editBusinessInfo?.name || "");
  const [busTemplate, setBusTemplate] = useState(
    editBusinessInfo?.template || ""
  );
  const [operations, setOperations] = useState<Operation[]>(
    editBusinessInfo?.operations || []
  );
  const [type, setType] = useState<Business["process"]>(
    editBusinessInfo?.process || "crm"
  );
  const [namespace, setNamespace] = useState(editBusinessInfo?.namespace || "");
  const [campaingId, setCampaingId] = useState(
    editBusinessInfo?.campaign || ""
  );

  const ctrlAddOperation = (e: KeyboardEvent) => {
    if (e.key === "a" && e.ctrlKey) {
      onAddOperation();
    }
  };

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("keydown", ctrlAddOperation);
    return () => {
      window.removeEventListener("keydown", ctrlAddOperation);
    };
  }, []);

  const onAddOperation = () => {
    const newId = "temp" + (1000 + Math.floor(1000 * Math.random()));
    const newOperation: Operation = {
      id: newId,
      businessId: id,
      name: "",
      template: "",
      value: undefined,
      send: false,
      count: 0,
      total: 0,
      namespace: "",
      campaingId: "",
    };
    //console.log(newOperation.id, operations);
    setOperations((state) => [newOperation, ...state]);
  };

  const onRemoveOperation = (arg: { id: string }) => () => {
    const newOper = operations.filter((op) => op.id !== arg.id);
    setOperations(newOper);
  };

  const onNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newName = ev.target.value.replace(/ +/g, " ");
    setBusName(newName);
  };

  const onTemplateChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newTemplate = ev.target.value.replace(/ +/g, " ");
    setBusTemplate(newTemplate);
  };

  const onNamespaceChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setNamespace(ev.target.value);
  };

  const onCampaingIdChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setCampaingId(ev.target.value);
  };

  const onOpPropertyChange =
    (property: keyof Operation) =>
    (arg: { id: string }) =>
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newValue = ev.target.value.replace(/ +/g, " ");
      const newOper = operations.map((it) =>
        it.id === arg.id ? { ...it, [property]: newValue } : it
      );
      setOperations(newOper);
    };

  const isSubmitDisabled =
    !(
      busName.length > 4 &&
      operations.every((op) => op.name.length > 4 && op.template.length > 4) &&
      (busTemplate.length > 4 || operations.length > 0)
    ) ||
    namespace === "" ||
    campaingId === "";

  const onSubmit = () => {
    const bus: Business = {
      id: id,
      name: busName,
      value: editBusinessInfo?.value,
      top: editBusinessInfo?.top || false,
      priority: editBusinessInfo?.priority || -1,
      operations: operations.length !== 0 ? operations : undefined,
      template: busTemplate,
      process: type,
      send: editBusinessInfo?.send || false,
      count: editBusinessInfo?.count || 0,
      total: editBusinessInfo?.total || 0,
      campaign: campaingId,
      namespace: namespace,
    };
    onFinish(bus);
  };

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, []);

  return (
    <FormModal
      title={editBusinessInfo ? "Editar negocio" : "Agregar negocio"}
      buttonProps={{
        ...{
          buttonTitle: editBusinessInfo ? "Aceptar" : "Agregar negocio",
          onClick: onSubmit,
          LeftIcon: CancelIcon,
          disabled: isSubmitDisabled,
        },
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <div ref={scrollRef} className={styles.container}>
        <div className={styles.row}>
          <StyledInput
            autoFocus
            label={"Ingresa nombre del negocio"}
            value={busName}
            onChange={onNameChange}
          />
          <StyledInput
            label={"Ingresa nombre de plantilla"}
            value={busTemplate}
            onChange={onTemplateChange}
          />
        </div>
        <div className={styles.row}>
          <StyledInput
            autoFocus
            label={"Ingresa id de campaña"}
            value={campaingId}
            onChange={onCampaingIdChange}
          />
          <StyledInput
            label={"Ingresa namespace"}
            value={namespace}
            onChange={onNamespaceChange}
          />
        </div>
        <div className={styles.rowButtons}>
          <Button
            buttonTitle={"Agregar suboperación"}
            title={"Agregar ( Ctrl + A )"}
            styleType={"secondary"}
            LeftIcon={BookIcon}
            onClick={onAddOperation}
            disabled={false}
          />
          <Switch<Business["process"]>
            labelLeft="CRM"
            labelRight="Gobierno de datos"
            valueLeft={"crm"}
            valueRight={"gdd"}
            value={type}
            onClick={setType}
          />
        </div>
        <div className={styles.newOperationContainer}>
          {operations.map((op) => {
            return (
              <div className={styles.row}>
                <div className={styles.dobleRow}>
                  <div key={op.id} className={styles.row}>
                    <StyledInput
                      autoFocus
                      label={"Ingresa nombre del negocio"}
                      value={op.name}
                      onChange={onOpPropertyChange("name")(op)}
                    />
                    <StyledInput
                      label={"Ingresa nombre de plantilla"}
                      value={op.template}
                      onChange={onOpPropertyChange("template")(op)}
                    />
                  </div>
                  <div className={styles.row}>
                    <StyledInput
                      label={"Ingresa id de campaña"}
                      value={op.campaingId}
                      onChange={onOpPropertyChange("campaingId")(op)}
                    />
                    <StyledInput
                      label={"Ingresa namespace"}
                      value={op.namespace}
                      onChange={onOpPropertyChange("namespace")(op)}
                    />
                  </div>
                </div>
                <button
                  className={styles.eraseButton}
                  onClick={onRemoveOperation(op)}
                >
                  <TrashIcon />
                  <div>Eliminar</div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </FormModal>
  );
};

export default EditBusinessModal;
