import axios from "axios";
import { Business, Operation, ResponseBusiness } from "../types";
import {
  business2ResponseBusiness,
  operation2ResponseOperation,
  responseBusiness2Business,
} from "../utils/utils";

const apiUrl = `${process.env.REACT_APP_API_URL}/negocios`;

type ResponseBusinessCatalogue = {
  data: ResponseBusiness[];
};

export const businessCatalogue = (): Promise<Business[]> => {
  return axios
    .get<ResponseBusinessCatalogue>(apiUrl + "/consulta")
    .then((resp) => {
      //console.log("businessCatalogue", resp);
      const formatedData: Business[] = resp.data.data.map(
        responseBusiness2Business
      );

      return formatedData.sort((it, ti) =>
        it.top > ti.top
          ? 1
          : it.top < ti.top
          ? -1
          : it.priority > ti.priority
          ? 1
          : -1
      );
    })
    .catch((err) => {
      console.error("Error in business catalogue service");

      return [];
    });
};

export const saveBusiness = (business: Business[]) => {
  const formatedBusiness = business.map(business2ResponseBusiness);
  const info = {
    listNegocios: formatedBusiness,
  };

  //console.log("datos peticion", { info });
  return axios.post(apiUrl + "/actualiza", info);
};

export const createBusiness = (
  newBus: Business,
  currentBusiness: Business[]
) => {
  const info = business2ResponseBusiness(newBus);
  // {
  //   negocio: newBus.name,
  //   cantidad: newBus.value,
  //   prioridad: newBus.priority,
  //   top: newBus.top ? 1 : 0,
  //   plantilla: newBus.template,
  //   proceso: newBus.process === "crm" ? "CRM" : "GDD",
  //   subNegocios: [],
  // };
  return axios
    .post<ResponseBusinessCatalogue>(apiUrl + "/alta", info)
    .then((resp) => {
      //console.log("alta negocio exitosa", { currentBusiness });
      const formatedData: Business[] = resp.data.data.map(
        responseBusiness2Business
      );

      const oldBus = currentBusiness.filter(
        (it) => formatedData.map((el) => el.id).indexOf(it.id) === -1
      );
      const newBus = formatedData.filter(
        (it) => currentBusiness.map((el) => el.id).indexOf(it.id) === -1
      );
      //console.log({ oldBus, newBus });
      const index = oldBus.map((it) => it.name).indexOf(newBus[0].name);
      if (newBus.length === 1 && index !== -1) {
        return { oldId: oldBus[0].id, newId: newBus[0].id };
      }
      return { oldId: "", newId: "" };
    })
    .catch((err) => {
      console.error("error en alta negocio");
      return { oldId: "", newId: "" };
    });
};

export const eraseBusiness = (bus: Business) => {
  const info = {
    id: bus.id,
  };
  return axios
    .post<ResponseBusinessCatalogue>(apiUrl + "/eliminarNegocio", info)
    .then((resp) => {
      //console.log("Eliminacion de negocio exitosa", resp.data);
      const formatedData: Business[] = resp.data.data.map(
        responseBusiness2Business
      );

      return formatedData.sort((it, ti) =>
        it.priority > ti.priority ? 1 : -1
      );
    })
    .catch((err) => {
      console.error("eliminacion de negocio fallida", err);
      return [];
    });
};

export const createOperation = (
  newOp: Operation,
  busPair: { newId: string; oldId: string },
  currentBusiness: Business[]
): Promise<{ newOpId: string; oldOpId: string }> => {
  const info = operation2ResponseOperation(newOp);
  // {
  //   subNegocio: newOp.name,
  //   cantidad: newOp.value,
  //   idNegocio: busPair.newId,
  //   subPlantilla: newOp.template,
  // };
  return axios
    .post<ResponseBusinessCatalogue>(apiUrl + "/altaSub", info)
    .then((resp) => {
      //console.log("alta operacion exitosa", resp.data.data);
      const formatedData: Business[] = resp.data.data.map(
        responseBusiness2Business
      );

      const formatedOperations = formatedData.find(
        (it) => it.id === busPair.newId
      )?.operations;
      const currentOperations = currentBusiness.find(
        (it) => it.id === busPair.newId
      )?.operations;

      //console.log({ formatedData, formatedOperations, currentOperations });

      if (formatedOperations && currentOperations) {
        const oldOp = currentOperations.filter(
          (it) => formatedOperations.map((el) => el.id).indexOf(it.id) === -1
        );
        const newOp = formatedOperations.filter(
          (it) => currentOperations.map((el) => el.id).indexOf(it.id) === -1
        );
        //console.log({ oldOp, newOp });
        const index = oldOp?.map((it) => it.name).indexOf(newOp[0].name);
        if (newOp.length === 1 && index !== -1) {
          let newPair = { oldOpId: oldOp[0].id, newOpId: newOp[0].id };
          //console.log({ newPair });
          return newPair;
        }
      }
      return { oldOpId: "", newOpId: "" };
    })
    .catch((err) => {
      console.error("error en alta negocio");
      return { oldOpId: "", newOpId: "" };
    });
};
