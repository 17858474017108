import React from "react";

import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as TipIcon } from "./tipIcon.svg";

import styles from "./PercentageLoader.module.css";

export type PercentageLoaderProps = {
  percentage: number;
};

const Loader: React.FC<PercentageLoaderProps> = ({ percentage }) => {
  const width = 320;

  return (
    <div className={styles.container}>
      <div className={styles.background}></div>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.bar} style={{ width: `${width}px` }}>
        <div
          className={styles.filled}
          style={{ width: `${(width * percentage) / 100}px` }}
        >
          <div className={styles.label}>
            <div className={styles.number}>{Math.floor(percentage)}%</div>
            <div className={styles.tip}>
              <TipIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
