import React from "react";

import SuccessModal from "../SuccessModal";

export type SaveConfirmationProps = {
  type: "success" | "error";
  onClose: (arg: any) => void;
};

const SaveConfirmation: React.FC<SaveConfirmationProps> = ({
  type,
  onClose,
}) => {
  const title =
    type === "success"
      ? "Acción realizada con éxito"
      : "Lo sentimos, hubo un problema";
  const content =
    type === "success"
      ? "El TIER se asignó correctamente."
      : "La acción no se guardó correctamente, intenta más tarde";

  return (
    <SuccessModal
      title={title}
      content={content}
      type={type}
      onClose={onClose}
    />
  );
};

export default SaveConfirmation;
