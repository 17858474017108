import React, { useState } from "react";

import SuccessModal from "../SuccessModal";
import FormModal from "../FormModal";

import styles from "./RemoveBusiness.module.css";

export type RemoveBusinessProps = {
  onCancel: (arg: void) => void;
  onFinish: (arg: void) => void;
};

const RemoveBusiness: React.FC<RemoveBusinessProps> = ({
  onCancel,
  onFinish,
}) => {
  const [step, setStep] = useState(0);

  if (step === 0) {
    return (
      <FormModal
        buttonProps={{
          buttonTitle: "Sí, eliminar",
          onClick: () => {
            setStep(1);
          },
        }}
        title="Eliminación negocios para el TIER"
        onCancel={() => {
          onCancel();
        }}
      >
        <div className={styles.oneLine}>
          ¿Estas seguro de querer elmiminar 1 negocio?
        </div>
      </FormModal>
    );
  } else {
    return (
      <SuccessModal
        type="success"
        title="Eliminación exitosa"
        content="Se reasignará automáticamente el número de pruebas"
        onClose={() => {
          onFinish();
        }}
      />
    );
  }
};

export default RemoveBusiness;
