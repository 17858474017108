import React from "react";

import { ReactComponent as Down } from "./down.svg";
import { ReactComponent as Up } from "./up.svg";

import styles from "./ToggleArrow.module.css";

export type ToggleArrowProps = {
  value: boolean;
};

const ToggleArrow: React.FC<ToggleArrowProps> = ({ value }) => {
  return <div className={styles.container}>{value ? <Up /> : <Down />}</div>;
};

export default ToggleArrow;
