import TextField, { TextFieldProps } from "@mui/material/TextField";

export type StyledInputProps = TextFieldProps;

const StyledInput: React.FC<StyledInputProps> = ({ sx, ...rest }) => {
  return (
    <div style={{ width: "100%" }}>
      <TextField
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9B9B99",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF995B !important",
          },
          "& .MuiInputLabel-shrink": {
            color: "#FF995B !important",
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default StyledInput;
