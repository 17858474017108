import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTier } from "../../slices/tiers";
import { RootState } from "../../store";
import { Tier } from "../../types";

import Radio from "../Radio";

import styles from "./TierList.module.css";

export type TierListProps = {};

const TierList: React.FC<TierListProps> = () => {
  const dispatch = useDispatch();

  const tiers: Tier[] | undefined = useSelector(
    (state: RootState) => state.tier.tierList
  );
  const currTier: Tier | undefined = useSelector(
    (state: RootState) => state.tier.currTier
  );

  const onClick = (newTier: Tier) => () => {
    const nextTier = tiers
      ?.map((it) => it)
      .sort((it, el) => it.value - el.value)
      .find((it) => it.value > (currTier?.value || 0));

    if (
      newTier.value <= (currTier?.value || 0) ||
      newTier.value === nextTier?.value
    ) {
      dispatch(setCurrentTier(newTier));
    }
  };

  const radioLabel = (value: number) => {
    const str: string =
      value === Number.POSITIVE_INFINITY ? "Infinito" : value.toLocaleString();
    return `Tier ${str}`;
  };

  return (
    <div className={styles.tierList}>
      <div className={styles.tierListTitle}>Selecciona TIER:</div>
      {tiers?.map((tier) => (
        <Radio
          key={tier.id}
          value={currTier?.id === tier.id}
          label={radioLabel(tier.value)}
          onClick={onClick(tier)}
        />
      ))}
    </div>
  );
};

export default TierList;
