import React, { MouseEventHandler } from "react";

import { ReactComponent as CheckedIcon } from "./checked.svg";
import { ReactComponent as EmptyIcon } from "./empty.svg";

import styles from "./Radio.module.css";

export type RadioProps = {
  value: boolean;
  label: string;
  onClick: MouseEventHandler<HTMLLabelElement>;
};

const Radio: React.FC<RadioProps> = ({ value, label, onClick }) => {
  return (
    <label className={styles.container} onClick={onClick}>
      {value ? <CheckedIcon /> : <EmptyIcon />}
      {label}
    </label>
  );
};

export default Radio;
