import axios from "axios";

const nudeApiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrl = `${process.env.REACT_APP_API_URL}`;

type ProcessResponse = {
  data:any;
  status: string;
  message: string;
  porcentaje: string;
};

export const processAdvance = (): Promise<number | null> => {
  return axios
    .get<ProcessResponse>(`${apiUrl}/porcentaje/consulta`)
    .then((resp) => {      
      return resp.data.data.searches.porcentaje === null
        ? null
        : Number(resp.data.data.searches.porcentaje);
    });
};

export const executeProcess = (): Promise<void> => {
  const hardcodedArgs = {
    q: 1,
    semana: 2,
  };

  const crm = axios.get(`${nudeApiUrl}/crmf2/generar`).catch((err) => {
    console.error("Error ejecucion crm", err);
  });

  const gdd = axios
    .get(`${nudeApiUrl}/gddf2/crear`)
    .catch((err) => console.error("Error ejecucion gdd", err));

  return Promise.all([crm, gdd])
    .then((values) => {
      //console.log("Promise all", values);
      return;
    })
    .catch((err) => {
      console.error("Problema con ejecucion", err);
    });
};

export const executeF3 = (): Promise<void> => {
  const crm = axios.get(`${nudeApiUrl}/crmf3/enviar`);
  const gdd = axios.get(`${nudeApiUrl}/gddf3/enviar`);

  return Promise.all([crm, gdd]).then(() => {});
};

export const rebootProcess = () => {
   return axios.post(apiUrl+"/porcentaje/consulta",{})
    .then((response) => {
      return response.data.data.reinicio.exito;
      
    })
    .catch(error => {
      console.error("Problema con ejecucion", error);
    });
};
