import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector } from "react-redux";

import Button from "../Button";
import Table from "./Table";
import RemoveBusiness from "../RemoveBusiness";

import { ReactComponent as ExportIcon } from "./exportIcon.svg";
import { ReactComponent as SaveIcon } from "./saveIcon.svg";
import styles from "./FoldedTable.module.css";

import { RootState } from "../../store";

import { formatedTierValue } from "../../utils/utils";

import { saveBusiness } from "../../services/businessService";
import { tierUpdate } from "../../services/tierService";
import {
  processAdvance,
  rebootProcess,
  executeF3,
  executeProcess,
} from "../../services/process";

import SaveConfirmation from "../SaveConfirmation";
import EditBusinessModal from "../EditBusinessModal";
import FormModal from "../FormModal";
import CircleLoader from "../CircleLoader/CircleLoader";
import SaveWarningModal from "../SaveWarningModal/SaveWarningModal";
import Loader from "../Loader";

import {
  businessDifference,
  changeOperValue,
  createAllNewBusiness,
  eraseBusinessList,
  onActivateLogic,
} from "../../utils/businessUtils";

import { Business, Operation } from "../../types";
import { ModalProps } from "../SuccessModal/Modal";

export type FoldedTableProps = {
  initialBusiness: Business[] | undefined;
};

const FoldedTable: React.FC<FoldedTableProps> = ({ initialBusiness }) => {
  const [editBusinessInfo, setEditBusinessInfo] = useState<
    Business | undefined
  >(undefined);
  const currTier = useSelector((state: RootState) => state.tier.currTier);
  const loadingTier = useSelector((state: RootState) => state.tier.loading);
  const [business, setBusiness] = useState<Business[] | undefined>([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [businessToRemove, setBusinessToRemove] = useState<
    undefined | Business
  >(undefined);

  const [businessSavedCopy, setBusinessSavedCopy] = useState<
    Business[] | undefined
  >([]);

  const [loadingSave, setLoadingSave] = useState(false);

  const [percentage, setPercentage] = useState(0);
  const [isLoaderSuccesss, setIsLoaderSuccess] = useState<ModalProps["type"]>();

  const [isRemoveBusiness, setIsRemoveBusiness] = useState(false);
  const [isAddBusiness, setIsAddBusiness] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isSaveConfirmation, setIsSaveConfirmation] = useState(false);
  const [isExecuteConfirmation, setIsExecuteConfirmation] = useState(false);
  const [isSaveWarningVisible, setIsSaveWarningVisible] = useState(false);

  useEffect(() => {
    const newBusiness = initialBusiness?.map((it) => it) || [];
    //console.log("foldedTable", { newBusiness, business });
    if (newBusiness) {
      setBusiness(newBusiness);
      setBusinessSavedCopy(newBusiness);
    }
  }, [initialBusiness]);

  const onValueChange =
    (busId: string) => (ev: ChangeEvent<HTMLInputElement>) => {
      if (business) {
        const newValue =
          ev.target.value !== "" ? Number(ev.target.value) : undefined;
        const newBusiness = [...business];
        const index = newBusiness.findIndex((it) => it.id === busId);
        const newBus = { ...newBusiness[index] };
        if (!newValue || newValue + newBus.count <= newBus.total) {
          newBus.value = newValue;
          newBusiness[index] = newBus;
        }
        setBusiness(newBusiness);
      }
    };

  const onValueOperChange =
    (operId: string, busId: string) => (ev: ChangeEvent<HTMLInputElement>) => {
      const newValue =
        ev.target.value === "" ? undefined : Number(ev.target.value);
      setBusiness(changeOperValue(busId, operId, newValue));
    };

  const tierTitle = (value: number | undefined) =>
    !value || value === Number.POSITIVE_INFINITY
      ? "Tier ..."
      : formatedTierValue(value);

  const onRemoveBusiness = (bus: Business) => {
    setBusiness((state) => state?.filter((it) => it.id !== bus.id));
  };

  const radioButtonClick = (bus: Business) => (ev: any) => {
    const isTopFull = (business?.filter((bus) => bus.top).length || 0) >= 3;

    const newBusiness = business
      ?.map((it) => {
        return it.id === bus.id ? { ...it, top: !it.top && !isTopFull } : it;
      })
      .sort((it, el) => {
        return it.id === bus.id && it.top
          ? 1
          : el.id === bus.id && el.top
          ? -1
          : it.top > el.top
          ? 1
          : it.top < el.top
          ? -1
          : 0;
      });

    setBusiness(newBusiness);
  };

  const updateBusinessInList = (newBus: Business) => {
    const businessList = business || [];
    const index = businessList.map((it) => it.id).indexOf(newBus.id);
    if (index !== -1) {
      businessList[index] = newBus;
      setBusiness(businessList);
      return;
    }
    setBusiness([newBus, ...businessList]);
    return;
  };

  const submit = () => {
    const { toErase, toCreate } = businessDifference(
      business || [],
      initialBusiness || []
    );

    if (business) {
      setLoadingSave(true);
      createAllNewBusiness(toCreate, business)
        .then((respBusList) => eraseBusinessList(toErase, respBusList))
        .then((finalBusiness) => {
          saveBusiness(finalBusiness);
          return finalBusiness;
        })
        .then((finalBusiness) => {
          if (currTier) {
            return tierUpdate(currTier).then((resp) => {
              return finalBusiness;
            });
          } else {
            return finalBusiness;
          }
        })
        .then((resp) => {
          setBusiness(resp);
          setBusinessSavedCopy(resp);
          setSaveSuccess(true);
          setIsSaveConfirmation(true);
        })
        .catch((err) => {
          console.error("save", err);
          setSaveSuccess(false);
          setIsSaveConfirmation(true);
        })
        .finally(() => {
          setLoadingSave(false);
        });
    }
  };

  const isSubmitDisabled = () => {
    if (currTier && business) {
      const isBussinesFilled = (bus: Business): boolean =>
        !!bus.value &&
        (bus.operations
          ? bus.operations.reduce((acc, oper) => acc && !!oper.value, true)
          : true);

      const isAllFilled = business.reduce(
        (acc, it) => acc && isBussinesFilled(it),
        true
      );

      if (isAllFilled) {
        if (currTier.value === Number.POSITIVE_INFINITY) return false;
        const rule4Business = (bus: Business) =>
          !bus.operations
            ? true
            : bus.value ===
              bus.operations.reduce(
                (acc, it) => (it.value ? it.value + acc : 0),
                0
              );
        const busTotal = business.reduce(
          (acc, bus) => (bus.value ? acc + bus.value : 0),
          0
        );

        return !(
          busTotal === currTier.value &&
          business.reduce((acc, it) => acc && rule4Business(it), true)
        );
      }
    }

    return true;
  };

  const onEditBusiness = (bus: Business | undefined) => (ev: any) => {
    setEditBusinessInfo(bus);
    setIsAddBusiness(true);
  };

  const onEraseBusiness = (bus: Business) => (ev: any) => {
    setBusinessToRemove(bus);
    setIsRemoveBusiness(true);
  };

  const callAgain = (
    resp: number | null
  ): number | null | Promise<number | null> => {
    //console.log("callAgain", resp, typeof resp);
    if (resp !== null && resp < 100) {
      setPercentage(resp);
      return new Promise((res, rej) => setTimeout(res, 3000)).then(() => {
        return processAdvance().then((re) => {
          //console.log({ resp, re });
          return callAgain(re);
        });
      });
    } else if (resp === 100) {
      setPercentage(resp);
      return 100;
    } else {
      throw new Error("Percentage service problem");
    }
  };

  const handleExecute = () => {
    setPercentage(0);
    setIsLoaderSuccess(undefined);
    setIsLoader(true);
    rebootProcess()
      .then((resp:boolean) => {
        if(resp){
          executeProcess();
          return callAgain(0);
        }
      })
      .then((resp:any) => {
        return executeF3();
      })
      .then((resp:any) => {
        setIsLoaderSuccess("success");
      })
      .catch((err:any) => {
        setIsLoaderSuccess("error");
        console.error(err);
      });
  };

  const executeConfirmation = () => {
    setIsExecuteConfirmation(true);
  };

  const onActivateSendClick =
    (bus: Business, oper?: Operation) => (ev: any) => {
      const newBusiness = onActivateLogic(business, bus, oper);
      setBusiness(newBusiness);
    };

  const isExecuteDisabled =
    !business ||
    !business.length ||
    isSubmitDisabled() ||
    JSON.stringify(business) !== JSON.stringify(businessSavedCopy);

  return (
    <div className={styles.container}>
      <div className={styles.tierContainer}>
        <span>Distribución de registros</span>
        <span>{tierTitle(currTier?.value)}</span>
      </div>
      <Table
        business={business}
        onValueOperChange={onValueOperChange}
        onValueChange={onValueChange}
        radioButtonClick={radioButtonClick}
        onEditBusiness={onEditBusiness}
        onEraseBusiness={onEraseBusiness}
        onActivateSendClick={onActivateSendClick}
      />
      <div className={styles.buttonsContainer}>
        <Button
          buttonTitle={"Agregar negocio"}
          LeftIcon={ExportIcon}
          onClick={onEditBusiness(undefined)}
        />
        <Button
          buttonTitle={"Guardar cambios"}
          LeftIcon={SaveIcon}
          onClick={submit}
          disabled={isSubmitDisabled()}
        />
        <Button
          buttonTitle={"Ejecutar encuesta"}
          LeftIcon={ExportIcon}
          onClick={executeConfirmation}
          disabled={isExecuteDisabled}
        />
      </div>
      {isRemoveBusiness && (
        <RemoveBusiness
          onCancel={() => {
            setIsRemoveBusiness(false);
            setBusinessToRemove(undefined);
          }}
          onFinish={() => {
            if (businessToRemove) onRemoveBusiness(businessToRemove);
            setIsRemoveBusiness(false);
            setBusinessToRemove(undefined);
          }}
        />
      )}

      {isAddBusiness && (
        <EditBusinessModal
          editBusinessInfo={editBusinessInfo}
          onCancel={() => {
            setIsAddBusiness(false);
          }}
          onFinish={(newBusinessInfo) => {
            updateBusinessInList(newBusinessInfo);
            setIsAddBusiness(false);
            setIsSaveWarningVisible(true);
          }}
        />
      )}
      {isSaveConfirmation && (
        <SaveConfirmation
          type={saveSuccess ? "success" : "error"}
          onClose={() => {
            setIsSaveConfirmation(false);
          }}
        />
      )}
      {(loadingTier === "pending" || business === undefined || loadingSave) && (
        <CircleLoader />
      )}
      {isLoader && (
        <Loader
          percentage={percentage}
          type={isLoaderSuccesss}
          onClose={() => {
            //setIsLoader(false);
            window.location.reload();
          }}
        />
      )}
      {isSaveWarningVisible && (
        <SaveWarningModal
          onConfirmation={() => {
            setIsSaveWarningVisible(false);
          }}
        />
      )}
      {isExecuteConfirmation && (
        <FormModal
          buttonProps={{
            buttonTitle: "Sí, ejecutar",
            onClick: () => {
              setIsExecuteConfirmation(false);
              handleExecute();
            },
          }}
          title={"Ejecutar encuestas"}
          onCancel={() => {
            setIsExecuteConfirmation(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "border-box",
              width: "100%",
              padding: "40px 80px",
              overflow: "auto",
            }}
          >
            ¿Estás seguro de ejecutar las encuestas?
          </div>
        </FormModal>
      )}
    </div>
  );
};

export default FoldedTable;
