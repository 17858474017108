import axios from "axios";

import { Tier } from "../types";

const apiUrl = `${process.env.REACT_APP_API_URL}/tier`;

type TierCatalogueResponse = {
  data: { id: number; orden: number; cantidad: number; actual: number }[];
};

export const tierCatalogue = (): Promise<Tier[]> => {
  return axios.get<TierCatalogueResponse>(apiUrl + "/consulta").then((res) => {
    //console.log("tierCatalogue", res);
    const formatedData: Tier[] = res.data.data.map((it) => ({
      id: String(it.id),
      order: it.orden,
      value: it.cantidad !== -1 ? it.cantidad : Number.POSITIVE_INFINITY,
      current: !!it.actual,
    }));

    return formatedData.sort((it, ti) => (it.order > ti.order ? 1 : -1));
  });
};

export const tierRegistration = (
  order: number,
  quantity: number,
  current: boolean
) => {
  const args = {
    orden: 5,
    cantidad: 10000,
    actual: current ? 1 : 0,
  };
  return axios.post(apiUrl + "/alta", args).then((res) => {
    //console.log("res", res);
  });
};

export const tierUpdate = (tier: Tier) => {
  const args = { id: tier.id };
  return axios
    .post<TierCatalogueResponse>(apiUrl + "/actualiza", args)
    .then((res) => {
      //console.log("tierCatalogue", res);
      const formatedData: Tier[] = res.data.data.map((it) => ({
        id: String(it.id),
        order: it.orden,
        value: it.cantidad !== -1 ? it.cantidad : Number.POSITIVE_INFINITY,
        current: !!it.actual,
      }));

      return formatedData.sort((it, ti) => (it.order > ti.order ? 1 : -1));
    });
};
