import {
  Business,
  Operation,
  ResponseBusiness,
  ResponseOperation,
} from "../types";

export const formatedTierValue = (value: number) => {
  return value.toLocaleString();
};

export const business2ResponseBusiness = (rBus: Business): ResponseBusiness => {
  return {
    id: Number(rBus.id),
    negocio: rBus.name,
    cantidad: rBus.value || -1,
    prioridad: rBus.priority,
    top: rBus.top ? 1 : 0,
    plantilla: rBus.template || null,
    proceso: rBus.process?.toUpperCase() || null,
    subNegocios: rBus.operations?.map(operation2ResponseOperation),
    activo: rBus.send ? 1 : 0,
    indice: rBus.count,
    total: rBus.total,
    campaign: rBus.campaign,
    namespace: rBus.namespace,
  };
};

export const responseBusiness2Business = (bus: ResponseBusiness): Business => ({
  id: String(bus.id),
  name: bus.negocio,
  value: bus.cantidad !== -1 ? bus.cantidad : undefined,
  priority: bus.prioridad,
  top: !!bus.top,
  template: bus.plantilla,
  process: bus.proceso === "CRM" ? "crm" : "gdd",
  operations:
    !Array.isArray(bus?.subNegocios) || bus.subNegocios.length === 0
      ? undefined
      : bus.subNegocios?.map(responseOperation2Operation),
  send: bus.activo === 1,
  total: bus.total,
  count: bus.indice,
  namespace: bus.namespace,
  campaign: bus.campaign,
});

export const operation2ResponseOperation = (op: Operation): any => ({
  idSub: Number(op.id),
  subNegocio: op.name,
  cantidad: op.value || -1,
  idNegocio: Number(op.businessId),
  subPlantilla: op.template,
  activo: op.send ? 1 : 0,
  campaign: op.campaingId,
  namespace: op.namespace,
});

export const responseOperation2Operation = (
  rOp: ResponseOperation
): Operation => ({
  id: String(rOp.idSubNegocio),
  name: rOp.subNegocio,
  value: rOp.cantidad !== -1 ? rOp.cantidad : undefined,
  businessId: String(rOp.idNegocio),
  template: rOp.subPlantilla,
  send: rOp.activo === 1,
  count: Number(rOp.indice),
  total: Number(rOp.total),
  campaingId: rOp.campaign || "",
  namespace: rOp.namespace || "",
});
