import {
  createBusiness,
  createOperation,
  eraseBusiness,
} from "../services/businessService";
import { Business, Operation } from "../types";

export const changeIdOperationInBusinessArray = (
  pair: { newOpId: string; oldOpId: string },
  businessId: string,
  business: Business[]
): Business[] => {
  return business.map((el) =>
    el.id !== businessId
      ? el
      : {
          ...el,
          operations: el?.operations?.map((po) =>
            po.id !== pair.oldOpId
              ? po
              : { ...po, id: pair.newOpId, businessId: businessId }
          ),
        }
  );
};

export const businessDifference = (newBus: Business[], oldBus: Business[]) => {
  const toErase = oldBus.filter((it) => !newBus.find((el) => it.id === el.id));
  const toCreate = newBus.filter((it) => !oldBus.find((el) => it.id === el.id));

  //console.log({ toCreate, toErase });

  return {
    toErase: toErase,
    toCreate: toCreate,
  };
};

export const changeOperValue =
  (busId: string, operId: string, newValue: number | undefined) =>
  (state: Business[] | undefined) =>
    state?.map((it) =>
      it.id === busId
        ? {
            ...it,
            operations: it.operations?.map((el) => {
              if (
                el.id === operId &&
                (!newValue || newValue + el.count <= el.total)
              )
                return {
                  ...el,
                  value: newValue,
                };

              return el;
            }),
          }
        : it
    );

export const createAllNewBusiness = (
  toCreate: Business[],
  business: Business[]
): Promise<Business[]> =>
  toCreate.reduce<Promise<Business[]>>((acc, it) => {
    return acc.then((response) => {
      return createBusiness(it, response).then((resp) => {
        let newResponse = response?.map((el) =>
          el.id === resp.oldId ? { ...el, id: resp.newId } : el
        );
        if (it.operations) {
          const operationsToCreate = [...it.operations];
          //console.log({ operationsToCreate });
          return operationsToCreate
            .reduce<Promise<Business[]>>((acc, op) => {
              return acc.then((re: Business[]) => {
                return createOperation(op, resp, re).then((pair) => {
                  return changeIdOperationInBusinessArray(pair, resp.newId, re);
                });
              });
            }, Promise.resolve(newResponse))
            .then((resp) => {
              return resp;
            });
        } else {
          return newResponse;
        }
      });
    });
  }, Promise.resolve(business));

export const eraseBusinessList = (toErase: Business[], business: Business[]) =>
  toErase.reduce<Promise<Business[]>>((acc, it) => {
    return acc.then((curBus) => {
      return eraseBusiness(it).then((resp) => {
        return curBus.filter((el) => el.id !== it.id);
      });
    });
  }, Promise.resolve(business));

export const onActivateLogic = (
  business: Business[] | undefined,
  bus: Business,
  oper?: Operation
) => {
  const ind = business?.findIndex((it) => it.id === bus.id);
  const newBusiness = business?.map((it) => it);
  if (ind !== undefined && newBusiness !== undefined) {
    if (oper) {
      if (oper.count >= oper.total) return;
      const curBus = newBusiness[ind];
      const opInd = curBus.operations?.findIndex((op) => op.id === oper.id);
      if (opInd !== undefined && newBusiness[ind]["operations"] !== undefined) {
        const newOper = (newBusiness[ind]["operations"] || []).map((it) => it);
        newOper[opInd] = {
          ...newOper[opInd],
          send: !newOper[opInd]["send"],
        };
        if (newOper.some((it) => it.send)) {
          newBusiness[ind] = {
            ...newBusiness[ind],
            send: true,
            operations: newOper,
          };
        } else {
          newBusiness[ind] = {
            ...newBusiness[ind],
            send: false,
            operations: newOper,
          };
        }
      }
    } else if (bus.operations) {
      if (bus.count >= bus.total) return;
      if (bus.operations.some((it) => it.send)) {
        const newOper = (newBusiness[ind]["operations"] || []).map((it) => ({
          ...it,
          send: false,
        }));
        newBusiness[ind] = {
          ...newBusiness[ind],
          send: false,
          operations: newOper,
        };
      } else {
        const newOper = (newBusiness[ind]["operations"] || []).map((it) => ({
          ...it,
          send: it.count === it.total ? false : true,
        }));
        newBusiness[ind] = {
          ...newBusiness[ind],
          send: true,
          operations: newOper,
        };
      }
    } else {
      if (bus.count >= bus.total) return;
      newBusiness[ind] = {
        ...newBusiness[ind],
        send: !newBusiness[ind]["send"],
      };
    }
  }
  return newBusiness;
};
