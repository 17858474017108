import React, { useEffect, useState } from "react";

import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as TipIcon } from "./tipIcon.svg";

import styles from "./CircleLoader.module.css";
import Lottie from "lottie-react";
import animation from "./loader.json";

export type CircleLoaderProps = {};

const Loader: React.FC<CircleLoaderProps> = ({}) => {
  //const [percentage, setPercentage] = useState<number>(0);

  return (
    <div className={styles.container}>
      <div className={styles.background}></div>
      <div className={styles.logo}>
        <Lottie
          animationData={animation}
          style={{ height: "152px" }}
          loop={true}
        />
      </div>
    </div>
  );
};

export default Loader;
