import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { businessCatalogue } from "../services/businessService";
import { Business } from "../types";

interface BusinessState {
  businessList: Business[] | undefined;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: BusinessState = {
  businessList: [],
  loading: "idle",
};

export const fetchBusiness = createAsyncThunk(
  "fetchBusiness",
  async (thunkAPI) => {
    return businessCatalogue();
  }
);

const tierSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBusiness.pending, (state, action) => {
      //console.log("fetchTiers.pending");
      return { ...state, loading: "pending", tierList: [] };
    });
    builder.addCase(fetchBusiness.rejected, (state, action) => {
      //console.log("fetchTiers.rejected");
      return { ...state, loading: "failed", tierList: [] };
    });
    builder.addCase(fetchBusiness.fulfilled, (state, action) => {
      //console.log("fetchTiers.fulfilled");
      return {
        ...state,
        businessList: action.payload,
      };
    });
  },
});

export default tierSlice.reducer;
