import React, { MouseEventHandler } from "react";

import { ReactComponent as CheckedSquare } from "./checked.svg";
import { ReactComponent as EmptySquare } from "./empty.svg";
import { ReactComponent as CheckedCircle } from "./checkedCircle.svg";
import { ReactComponent as EmptyCircle } from "./emptyCircle.svg";
import { ReactComponent as MessageIcon } from "./messageIcon.svg";

import styles from "./Checkbox.module.css";
import { iconButtonClasses } from "@mui/material";

export type RadioProps = {
  value: boolean;
  label: string;
  onClick: MouseEventHandler<HTMLLabelElement>;
  iconType?: "square" | "circle" | "message";
};

type IconProps = {
  iconType: RadioProps["iconType"];
};

const CheckedIcon: React.FC<IconProps> = ({ iconType }) => {
  switch (iconType) {
    case "square":
      return <CheckedSquare color="#FF995B" />;
    case "circle":
      return <CheckedCircle color="#FF995B" />;
    case "message":
      return <MessageIcon color="#FF995B" />;
    default:
      return <CheckedSquare />;
  }
};

const EmptyIcon: React.FC<IconProps> = ({ iconType }) => {
  switch (iconType) {
    case "square":
      return <EmptySquare color="#9B9B99" />;
    case "circle":
      return <EmptyCircle />;
    case "message":
      return <MessageIcon color="#9B9B99" />;
    default:
      return <EmptySquare />;
  }
};

const Checkbox: React.FC<RadioProps> = ({
  value,
  label,
  onClick,
  iconType = "square",
}) => {
  return (
    <div className={styles.container}>
      <label onClick={onClick}>
        {value ? (
          <CheckedIcon iconType={iconType} />
        ) : (
          <EmptyIcon iconType={iconType} />
        )}
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
