import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tierCatalogue } from "../services/tierService";

import { Tier } from "../types";

interface TierState {
  currTier: Tier | undefined;
  tierList: Tier[] | undefined;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: TierState = {
  currTier: undefined,
  tierList: [],
  loading: "idle",
} as TierState;

export const fetchTiers = createAsyncThunk("fetchTiers", async (thunkAPI) => {
  return tierCatalogue();
});

const tierSlice = createSlice({
  name: "tier",
  initialState,
  reducers: {
    setCurrentTier: (state, action: PayloadAction<Tier>) => {
      return { ...state, currTier: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTiers.pending, (state, action) => {
      return { ...state, loading: "pending", tierList: [] };
    });
    builder.addCase(fetchTiers.rejected, (state, action) => {
      return { ...state, loading: "failed", tierList: [] };
    });
    builder.addCase(fetchTiers.fulfilled, (state, action) => {
      let currentTier = action.payload.find((it) => it.current);
      return {
        loading: "succeeded",
        tierList: action.payload,
        currTier: currentTier,
      };
    });
  },
});

export const { setCurrentTier } = tierSlice.actions;

export default tierSlice.reducer;
