import { configureStore } from "@reduxjs/toolkit";
import tier from "./slices/tiers";
import business from "./slices/business";

export const store = configureStore({
  reducer: {
    tier,
    business,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
