import React from "react";
import Button from "../Button";

import { ReactComponent as ButtonIcon } from "./rightArrow.svg";
import { ReactComponent as CancelIcon } from "./cancelIcon.svg";
import { ReactComponent as CheckIcon } from "./checkmark.svg";

import styles from "./Modal.module.css";
import classnames from "classnames";

export type ModalProps = {
  type?: "success" | "error" | "neutral";
  title: string;
  content: string;
  onClose: (arg: void) => void;
};

const Modal: React.FC<ModalProps> = ({
  type = "success",
  title,
  content,
  onClose,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.window}>
        <div
          className={classnames(styles.header, {
            [styles.headerSuccess]: type === "success",
            [styles.headerError]: type === "error",
            [styles.headerNeutral]: type === "neutral",
          })}
        >
          {type !== "neutral" &&
            (type === "success" ? <CheckIcon /> : <CancelIcon />)}
          {title}
        </div>
        <div className={styles.body}>
          <div className={styles.content}>{content}</div>

          <div className={styles.button}>
            <Button
              buttonTitle={"Entendido"}
              RightIcon={ButtonIcon}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
