import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Business } from "./types";

import styles from "./App.module.css";

import FoldedTable from "./components/FoldedTable";
import TierList from "./components/TierList";

import { AppDispatch, RootState } from "./store";
import { fetchTiers } from "./slices/tiers";
import { fetchBusiness } from "./slices/business";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  const business: Business[] | undefined = useSelector(
    (state: RootState) => state.business.businessList
  );
  useEffect(() => {
    dispatch(fetchTiers());
    dispatch(fetchBusiness());
    // eslint-disable-next-line
      }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Volumetría</div>
      <div className={styles.content}>
        <div className={styles.leftSideContainer}>
          <TierList />
        </div>
        <div className={styles.rightSideContainer}>
          <FoldedTable initialBusiness={business} />
        </div>
      </div>
    </div>
  );
}

export default App;
