import styles from "./Switch.module.css";
import cn from "classnames";

export type SwitchProps<T> = {
  value: T;
  labelLeft: string;
  labelRight: string;
  valueLeft: T;
  valueRight: T;
  onClick: (arg: T) => void;
};

const Switch = <T,>({
  value,
  labelLeft,
  labelRight,
  valueLeft,
  valueRight,
  onClick: onClickExternal,
}: SwitchProps<T>) => {
  const onClick = (val: T) => (ev: any) => {
    //const newEv = { ...ev, target: { ...ev.target, value: val } };
    onClickExternal(val);
  };

  return (
    <div className={styles.container}>
      <label
        onClick={onClick(valueLeft)}
        className={cn(styles.label, styles.labelLeft, {
          [styles.labelOn]: value === valueLeft,
          [styles.labelOff]: value !== valueLeft,
        })}
      >
        {labelLeft}
        <div className={styles.switchBackground}>
          <div
            className={cn(styles.ball, {
              [styles.ballTrue]: value === valueRight,
              [styles.ballFalse]: value === valueLeft,
            })}
          ></div>
        </div>
      </label>
      <label
        onClick={onClick(valueRight)}
        className={cn(styles.label, styles.labelRight, {
          [styles.labelOn]: value === valueRight,
          [styles.labelOff]: value !== valueRight,
        })}
      >
        {labelRight}
      </label>
    </div>
  );
};

export default Switch;
