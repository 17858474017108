import React, { useEffect, useState } from "react";
import PercentageLoader from "../PercentageLoader";
import SuccessModal from "../SuccessModal";

import type { ModalProps } from "../SuccessModal/Modal";

export type LoaderProps = {
  percentage: number;
  type: ModalProps["type"];
  onClose: () => void;
};

const Loader: React.FC<LoaderProps> = ({ percentage, type, onClose }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (type === "success" || type === "error") {
      setTimeout(() => setStep(1), 500);
    }
  }, [type]);

  return step === 0 ? (
    <PercentageLoader percentage={percentage} />
  ) : type === "success" ? (
    <SuccessModal
      type={"success"}
      title={"Ejecución exitosa"}
      content={"La ejecución de encuestas fue exitosa"}
      onClose={onClose}
    />
  ) : (
    <SuccessModal
      type={"error"}
      title={"Error"}
      content={"Hubo un error al ejecutar las encuestas"}
      onClose={onClose}
    />
  );
};

export default Loader;
