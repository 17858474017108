import { ChangeEvent, useLayoutEffect, useRef } from "react";

export interface ControlledInputProps
  extends React.HTMLProps<HTMLInputElement> {
  value: number | undefined;
  onChange: any;
}

const ControlledInput: React.FC<ControlledInputProps> = ({
  value,
  onChange: onChangeExternal,
  ...rest
}) => {
  const runAfterUpdate = useRunAfterUpdate();

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const val = ev.target.value;
    const plainVal = ev.target.value.replace(/[^0-9]/g, "");
    const formatedVal = Number(plainVal).toLocaleString();
    const plainLength = plainVal.length;
    //const length = plainLength;
    const formatedLength = formatedVal.length;
    const plainCursor = val
      .split("")
      .slice(ev.target.selectionStart || 0)
      .join()
      .replace(/,/g, "").length;
    //console.log({ val, plainVal, formatedVal, plainLength, plainCursor });

    const plain2formated = (arg: number, length: number) => {
      const result = length - (arg + Math.floor(arg / 3));
      return result < 0 ? 0 : result;
    };

    onChangeExternal({
      ...ev,
      target: { ...ev.target, value: ev.target.value.replace(/[^0-9]/g, "") },
    });

    runAfterUpdate(() => {
      ev.target.selectionStart = plain2formated(plainCursor, formatedLength);
      ev.target.selectionEnd = plain2formated(plainCursor, formatedLength);
    });
  };

  return (
    <input
      placeholder="..."
      value={value?.toLocaleString() || ""}
      onChange={onChange}
      {...rest}
    ></input>
  );
};

export default ControlledInput;

function useRunAfterUpdate() {
  const afterPaintRef = useRef<any>(null);
  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });

  const useRunAfterUpdate = (fn: any) => (afterPaintRef.current = fn);
  return useRunAfterUpdate;
}
