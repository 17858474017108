import React, { MouseEventHandler } from "react";
import Button from "../Button";
import { ButtonProps } from "../Button/Button";

import styles from "./FormModal.module.css";

export type ModalProps = {
  type?: "success" | "error";
  buttonProps: ButtonProps;
  title: string;
  onCancel: MouseEventHandler;
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({
  children,
  buttonProps,
  title,
  onCancel,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.window}>
        <div className={styles.header}>{title}</div>
        <div className={styles.body}>{children}</div>
        <div className={styles.controls}>
          <Button {...buttonProps} />
          <button className={styles.cancel} onClick={onCancel}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
