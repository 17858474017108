import React, { MouseEventHandler } from "react";

import cn from "classnames";
import styles from "./Button.module.css";

export interface ButtonProps extends React.ComponentProps<"button"> {
  buttonTitle: string;
  LeftIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  RightIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick: MouseEventHandler<HTMLButtonElement>;
  styleType?: "primary" | "secondary";
}

const Button: React.FC<ButtonProps> = ({
  buttonTitle,
  LeftIcon,
  RightIcon,
  onClick,
  styleType = "primary",
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(styles.button, {
        [styles.primary]: styleType === "primary",
        [styles.secondary]: styleType === "secondary",
      })}
      {...rest}
    >
      {!!LeftIcon && <LeftIcon />}
      <span>{buttonTitle}</span>
      {!!RightIcon && <RightIcon />}
    </button>
  );
};

export default Button;
